let online = true;

document.addEventListener('DOMContentLoaded', function() {
    let NotificationQueue = []

    window.addNotification = function(text){
        if(NotificationQueue[NotificationQueue.length - 1] == text) return;
        NotificationQueue.push(text);
        if(NotificationQueue.length == 1){
            showNotification();
        }
    }
    
    window.showNotification = function(){
        if(NotificationQueue.length == 0) return;
        document.getElementById('notification').innerHTML = NotificationQueue[0];
        document.getElementById('notification').dataset.visible = 'true';
        setTimeout(function(){
            document.getElementById('notification').dataset.visible = 'false';
            NotificationQueue.shift();
            setTimeout(function(){
                showNotification();
            }, 1000);
        }, 3000);
    }
    
    // if the page url is /history, change the page to history
    
    document.getElementsByClassName("videos-wrapper")[0].addEventListener("scroll", function(){
        // if it's not top of the page, show the back to top button
        if(document.getElementsByClassName("videos-wrapper")[0].scrollTop > 50){
            document.getElementsByClassName("clear-history")[0].dataset.visible = "hidden";
        } else {
            document.getElementsByClassName("clear-history")[0].dataset.visible = "visible";
        }
    });

    let video;
    window.loadVideo = async function(url){
        if(!online){
            addNotification('You are offline');
            return;
        }
        // check if the link is a valid tiktok url with the following regex
        // \bhttps?:\/\/(?:m|www|vm|vt)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b
        // also if it's just the id of the video it will work
        if(!url.match(/\bhttps?:\/\/(?:m|www|vm|vt)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/) && !url.match(/\d+/)){
            addNotification('❌ Invalid TikTok URL');
            return;
        } else {
            document.getElementById('video').src = "";
            document.getElementsByClassName("tt_loading")[0].style.display = "flex";
            document.getElementById('video').style.display = "none";
            document.getElementById('p_player').style.background = "#151820";
            document.getElementsByClassName("ui-right")[0].style.opacity = 0;
            document.getElementsByClassName("ui-bottom")[0].style.opacity = 0;
            changePage('player');

            try{
                // request the video from the server and show it in the player
                let response = await fetch('https://www.tikwm.com/api/?url=' + url);
                let data = await response.json();

                if(data.code == -1){
                    // go to the "catch" block
                    changePage('home');
                    addNotification('❌ Invalid TikTok URL');
                    return;
                }

                
                video = data.data;


                // set the page to /video/:id
                window.history.pushState({}, '', '/video/' + video.id);

                document.getElementById('likes-counter').innerHTML = formatNumber(video.digg_count);
                document.getElementById('comment-counter').innerHTML = formatNumber(video.comment_count);
                document.getElementById('share-counter').innerHTML = formatNumber(video.share_count);
                document.getElementById("username").innerHTML = video.author.nickname;
                document.getElementById("description").innerHTML = formatDescription(video.title);


                if(video.music_info.cover){
                    // set it as the ::after image of the #disc element
                    document.getElementById("disc-art").style.backgroundImage = "url(" + video.music_info.cover + ")";

                    // if the cover url and the author profile pic url are the same without additional parameters, make the disc-art element 50% smaller, also remove the resolution (e.g. 300x300)
                    // get everything before the last _ (there might be multiple _ in the url)
                    let coverUrl = video.music_info.cover.split("_");
                    coverUrl.pop();
                    coverUrl = coverUrl.join("_");
                    let authorUrl = video.author.avatar.split("_");
                    authorUrl.pop();
                    authorUrl = authorUrl.join("_");


                    if(coverUrl == authorUrl){
                        document.getElementById("disc-art").style.transform = "scale(0.5)";
                    } else {
                        document.getElementById("disc-art").style.transform = "scale(1)";
                    }

                    document.getElementById("user").style.backgroundImage = "url(" + video.author.avatar + ")";
                    // hide the &::before element
                } else {
                    // hide the &::after element
                    document.getElementById("disc-art").style.backgroundImage = "none";
                    // show the &::before element
                }


                // make the device's audio api show the first 10 characters of the video and the author
                console.log(video.music_info);
                if(video.music_info.title.startsWith("original sound - ")){
                    document.getElementsByClassName("audio-title")[0].innerHTML = "original sound"
                } else {
                    document.getElementsByClassName("audio-title")[0].innerHTML = video.music_info.title;
                }
                document.getElementsByClassName("audio-artist")[0].innerHTML = video.music_info.author ? "-&nbsp;&nbsp;" + video.music_info.author : "";

                // show the video
                if(video.images && video.images.length > 0){
                    document.getElementById("img-data").style.display = "flex";
                    document.getElementById("p_player").dataset.type = "image";
                    document.getElementById("image-slideshow").dataset.index = 0;
                    document.getElementById("img-content").innerHTML = "";
                    document.getElementById("image-slideshow").style.display = "block";
                    for(let i = 0; i < video.images.length; i++){
                        document.getElementById("img-content").innerHTML += "<img src='" + video.images[i] + "' class='image' id='image-" + i + "'>";
                    }
                    document.getElementById("img-total").innerHTML = video.images.length;
                } else {
                    document.getElementById("p_player").dataset.type = "video";
                    document.getElementById("img-data").style.display = "none";
                    // disable interactions
                    document.getElementById("image-slideshow").style.display = "none";
                }


                // the link downloads a video file
                // if there's a video propriety 
                let blob = await fetch(video.play).then(r => r.blob());
                let url_blob = URL.createObjectURL(blob);

                // once the video is loaded, show it and play it
                document.getElementById('video').onloadeddata = function(){
                    if(document.getElementById('video').paused){
                        document.getElementById("video-status").dataset.playing = 'false';
                    }
                }
                // if the video is wider than it's tall, make it 100% width and auto height
                // if the video is taller than it's wide, make it 100% height and auto width
                // if the video is a square, make it 100% width and height
                if(video.width > video.height){
                    document.getElementById('video').style.width = "100%";
                    document.getElementById('video').style.height = "auto";
                } else if(video.width < video.height){
                    document.getElementById('video').style.width = "auto";
                    document.getElementById('video').style.height = "100%";
                } else {
                    document.getElementById('video').style.width = "100%";
                    document.getElementById('video').style.height = "100%";
                }
                document.getElementById('video').src = url_blob
                document.getElementById('p_player').style.background = "#000";

                document.getElementById('video').style.display = "flex";
                document.getElementsByClassName("ui-right")[0].style.opacity = 1;
                document.getElementsByClassName("ui-bottom")[0].style.opacity = 1;

                document.getElementsByClassName("tt_loading")[0].style.display = "none";
                document.getElementById('video-status').style.display = "flex"; 

                // if it doesn't exist, create a local database
                if(!localStorage.getItem('history')){
                    localStorage.setItem('history', JSON.stringify([]));
                }

                // if the video already exists in the history, place it at the top
                let history = JSON.parse(localStorage.getItem('history'));
                for(let i = 0; i < history.length; i++){
                    if(history[i].id == video.id){
                        history.splice(i, 1);
                        break;
                    }
                }

                video.url = url;
                history.unshift(video);

                localStorage.setItem('history', JSON.stringify(history));
            } catch(e){
                changePage('home');
                addNotification('❌ An error occurred');
                console.error(e);
            }
        }
    }

    window.refreshHistory = function(){
        // load the history JSON from the local storage
        let history = JSON.parse(localStorage.getItem('history')) || [];
        if(history == null || history.length == 0){
            history = [];
            document.getElementsByClassName("videos-wrapper")[0].innerHTML = "<div class='no-history'><i class='fas fa-history'></i>History is empty!</div>";
            
            if(document.getElementsByClassName("clear-history")[0]){            
                document.getElementsByClassName("clear-history")[0].dataset.visible = "hidden";
            }
            return;
        }
        
        document.getElementsByClassName("videos-wrapper")[0].innerHTML = "";

        for(let i = 0; i < history.length; i++){
            let video = history[i];
            let videoHTML = `
                <div class="video" onclick="loadVideo('${video.url}')">
                    <img src="${video.cover}" alt="">
                    <div class="data">
                        <div class="author">
                            ${video.author.nickname}
                        </div>
                        <div class="description">
                            ${formatDescription(video.title.length > 100 ? video.title.substring(0, 100) + "..." : video.title)}
                        </div>
                        <div class="data-row">
                            <div class="data-item">
                                <i class="fas fa-eye"></i>
                                    ${formatNumber(video.play_count)}
                            </div>
                            <div class="data-item">
                                <i class="fas fa-heart"></i>
                                    ${formatNumber(video.digg_count)}
                            </div>
                            <div class="data-item">
                                <i class="fas fa-comment"></i>
                                    ${formatNumber(video.comment_count)}
                            </div>     
                    </div>
                </div>
            `;
            document.getElementsByClassName("videos-wrapper")[0].innerHTML += videoHTML;

            // add a "Clear history" button at the bottom
            if(i == history.length - 1){
                document.getElementsByClassName("videos-wrapper")[0].innerHTML += `
                    <div class="clear-history" onclick="clearHistory()">
                        <i class="fas fa-trash"></i>&nbsp;&nbsp;
                        Clear history
                    </div>
                `;
            }
        }
    }

    refreshHistory();

    window.clearHistory = function(){
        // confirm alert 
        if(!confirm("Are you sure you want to clear your history?")){
            return;
        } 

        localStorage.setItem('history', JSON.stringify([])); 
        refreshHistory(); 
        addNotification('History cleared')
    }
    
    window.changePage = function(page){
        document.getElementById("img-content").innerHTML = "";
        document.getElementById("img-data").style.display = "none";
        document.getElementById("image-slideshow").style.display = "none";
        switch(page){
            case 'home':
                // set the url to "/"
                window.history.pushState({}, '', '/');
                document.getElementById('video').src = ""
                refreshHistory();

                if(document.getElementById("tn-popup").dataset.visible == "true"){
                    document.getElementById("tn-popup").dataset.visible = "false";
                }


                document.getElementById('video').pause();
                document.getElementById("link").disabled = false;
                document.getElementById('video-status').style.display = "none";
                document.getElementById('p_home').dataset.visible = 'visible';
                document.getElementById('p_history').dataset.visible = 'hidden-right';
                document.getElementById('p_player').dataset.visible = 'hidden-right';
                document.getElementById('nav-menu').dataset.visible = 'true';
                document.getElementById('nav-player').dataset.visible = 'false';
                document.getElementById('m_home').dataset.selected = 'true';
                document.getElementById('m_history').dataset.selected = 'false';

                



                break;
            case 'history':
                // set the url to "/history"
                window.history.pushState({}, '', '/history');


                document.getElementById("link").disabled = true;
                document.getElementById('p_home').dataset.visible = 'hidden-left';
                document.getElementById('p_history').dataset.visible = 'visible';
                document.getElementById('p_player').dataset.visible = 'hidden-right';
                document.getElementById('nav-menu').dataset.visible = 'true';
                document.getElementById('nav-player').dataset.visible = 'false';
                document.getElementById('m_home').dataset.selected = 'false';
                document.getElementById('m_history').dataset.selected = 'true';
                break;
            case 'player':
                document.getElementById("link").disabled = true;
                document.getElementById("link").value = "";
                document.getElementById('p_home').dataset.visible = 'hidden-left';
                document.getElementById('p_history').dataset.visible = 'hidden-left';
                document.getElementById('p_player').dataset.visible = 'visible';
                document.getElementById('nav-menu').dataset.visible = 'false';
                document.getElementById('nav-player').dataset.visible = 'true';
                break;
        }
    }

    if(window.location.pathname == '/history'){
        changePage('history');
    } else if(window.location.pathname.startsWith('/video/')){    
        // load the video
        let videoId = window.location.pathname.split('/')[2];
        loadVideo(videoId);
              
        // document.getElementById('video').pause();
    } else {
        changePage('home');
    }


    window.togglePopup = function(content){
        if(content == 'default'){
            content = `
                <div class="list-container">
                    <div class="list-item" onclick="downloadVideo()">
                        <! -- download --> 
                        <i class="fas fa-download"></i> Download
                    </div>
                    <div class="list-item" onclick="shareVideo()">
                        <! -- share -->
                        <i class="fas fa-share-alt"></i> Share
                    </div>
                    <div class="list-item" onclick="toggleClearMode()">
                        <! -- clear mode -->
                        <i class="fas fa-sync-alt"></i> Clear Mode
                    </div>
                </div>
            `;
        }

        if(document.getElementById('tn-popup').dataset.visible == 'true'){
            document.getElementById('tn-popup').dataset.visible = 'false';
        } else {
            document.getElementById('tn-popup').dataset.visible = 'true';
            // send 100ms of vibration
            navigator.vibrate(50);
            document.getElementById('tn-content').innerHTML = content;
        }
    }

    window.shareVideo = function(){
        navigator.share({
            title: 'TikNot',
            url: window.location.href
        }).then(() => {
        })
        .catch(console.error);
    }



    let startX, endX;
    let startY, endY;
    let threshold = 50;
    document.addEventListener('touchstart', function(e){
        startX = e.changedTouches[0].screenX;
        startY = e.changedTouches[0].screenY;
    })

    document.addEventListener('touchend', function(e){
        endX = e.changedTouches[0].screenX;
        endY = e.changedTouches[0].screenY;
        
        if(document.getElementById('tn-popup').dataset.visible == 'true') return;
        if(document.getElementById('p_home').dataset.visible == 'visible'){
            if(startX > endX + threshold && Math.abs(startY - endY) < 100){
                changePage('history');
            }
        } else if(document.getElementById('p_history').dataset.visible == 'visible'){
            if(startX < endX - threshold && Math.abs(startY - endY) < 100){
                changePage('home');
            }
        } else if(document.getElementById('p_player').dataset.visible == 'visible' && document.getElementById("p_player").dataset.type == "image"){
            if(startX < endX - threshold && Math.abs(startY - endY) < 100){
                // if the user swipes right, either shift to the next image (move the div by 100vw) or don't do anything (if it's on the first image)
                if(document.getElementById('image-slideshow').dataset.index == 0){
                    return;
                } else {
                    document.getElementById('image-slideshow').dataset.index = parseInt(document.getElementById('image-slideshow').dataset.index) - 1;
                    document.getElementById('img-content').style.transform = `translateX(-${document.getElementById('image-slideshow').dataset.index * 100}vw)`;
                    // convert to int and add 1
                    document.getElementById('img-current').innerHTML = Number(document.getElementById('image-slideshow').dataset.index) + 1;
                }
            } else if(startX > endX + threshold && Math.abs(startY - endY) < 100){
                // if the user swipes left, either shift to the previous image (move the div by 100vw) or don't do anything (if it's on the last image)
                if(document.getElementById('image-slideshow').dataset.index == document.getElementById('img-content').children.length - 1){
                    return;
                } else {
                    document.getElementById('image-slideshow').dataset.index = parseInt(document.getElementById('image-slideshow').dataset.index) + 1;
                    document.getElementById('img-content').style.transform = `translateX(-${document.getElementById('image-slideshow').dataset.index * 100}vw)`;
                    document.getElementById('img-current').innerHTML = Number(document.getElementById('image-slideshow').dataset.index) + 1;
                }
            }
        }



    })

    document.addEventListener('keydown', function(e){
        if(document.getElementById('p_player').dataset.visible == 'visible' && document.getElementById("p_player").dataset.type == "image"){
            if(e.key == 'ArrowLeft'){
                // if the user swipes right, either shift to the next image (move the div by 100vw) or don't do anything (if it's on the first image)
                if(document.getElementById('image-slideshow').dataset.index == 0){
                    return;
                } else {
                    document.getElementById('image-slideshow').dataset.index = parseInt(document.getElementById('image-slideshow').dataset.index) - 1;
                    document.getElementById('img-content').style.transform = `translateX(-${document.getElementById('image-slideshow').dataset.index * 100}vw)`;
                    document.getElementById('img-current').innerHTML = Number(document.getElementById('image-slideshow').dataset.index) + 1;
                }
            } else if(e.key == 'ArrowRight'){
                // if the user swipes left, either shift to the previous image (move the div by 100vw) or don't do anything (if it's on the last image)
                if(document.getElementById('image-slideshow').dataset.index == document.getElementById('img-content').children.length - 1){
                    return;
                } else {
                    document.getElementById('image-slideshow').dataset.index = parseInt(document.getElementById('image-slideshow').dataset.index) + 1;
                    document.getElementById('img-content').style.transform = `translateX(-${document.getElementById('image-slideshow').dataset.index * 100}vw)`;
                    document.getElementById('img-current').innerHTML = Number(document.getElementById('image-slideshow').dataset.index) + 1;
                }
            }
        }
    })

    

    document.addEventListener('contextmenu', function(e){
        if(e.shiftKey){
            // open the default context menu
            return;
        }
        if(document.getElementById('p_player').dataset.visible == 'visible'){
            e.preventDefault();
            if(document.getElementById('tn-popup').dataset.visible == 'true'){
                togglePopup();
            } else {
                togglePopup("default");
            }
        }
    })


    document.addEventListener('click', function(e){
        if(document.getElementById('p_player').dataset.visible == 'visible'){
            if(document.getElementById('tn-popup').dataset.visible == 'true'){
                togglePopup();
            } else {
                if(e.target.id == 'video'){   
                    if(document.getElementById('video').paused){
                        document.getElementById('video').play();
                    } else {
                        document.getElementById('video').pause();
                    }
                }
            }
        }
        }
    )
});

// mobile fix for height
const documentHeight = () => {
    const doc = document.documentElement;

    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', documentHeight)
documentHeight()





// if the user goes offline
window.addEventListener('offline', function(){
    addNotification('Unable to connect');
    online = false;
})

// if the user goes online
window.addEventListener('online', function(){
    addNotification('Back online');
    online = true;
})

// if the video gets paused
document.getElementById("video").addEventListener('pause', function(){
    document.getElementById("video-status").dataset.playing = 'false';
})

// if the video gets played
document.getElementById("video").addEventListener('play', function(){
    document.getElementById("video-status").dataset.playing = 'true';
    // set the #progress-bar's width to the current time of the video every second
    setInterval(function(){
        document.getElementById("progress-bar").style.width = (document.getElementById("video").currentTime / document.getElementById("video").duration) * 100 + "%";
    }, 1000);

})


window.formatNumber = function(number){
    // if the number is 52800, return 52.8k
    // if the number is under 10k return the number unchanged
    if(number >= 1000000){
        return (number / 1000000).toFixed(1) + 'M';
    } else if(number >= 10000){
        return (number / 1000).toFixed(1) + 'k';
    } else {
        return number;
    }
}

window.formatDescription = function(description){
    // turn #hashtags and @mentions into bold text
    description = description.replace(/#(\w+)/g, '<b>#$1</b>');
    description = description.replace(/@(\w+)/g, '<b>@$1</b>');
    return description;
}

window.toggleClearMode = function(){
    document.getElementsByClassName("ui-right")[0].dataset.clear = document.getElementsByClassName("ui-right")[0].dataset.clear == 'true' ? 'false' : 'true';
    document.getElementsByClassName("ui-bottom")[0].dataset.clear = document.getElementsByClassName("ui-bottom")[0].dataset.clear == 'true' ? 'false' : 'true';
    document.getElementById("clearModeToggle").dataset.active = document.getElementById("clearModeToggle").dataset.active == 'true' ? 'false' : 'true';
}

window.downloadVideo = function(){
    // download the video
    if(video){
        addNotification('✔️ Starting download...');
        let a = document.createElement('a');
        a.href = document.getElementById('video').src;
        a.download = video.id + '_tiknot.mp4'; 
        a.click();
    }
}